export default function UnsupportedBrowser() {
  return (
    <div className="absolute w-full h-full flex">
      <div className="m-auto p-10 text-center">
        Onze interactieve 3D view is niet beschikbaar voor jouw browser. Gebruik
        Chrome, Firefox of een andere ondersteunde browser.
      </div>
    </div>
  );
}
