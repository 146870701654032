import "animate.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FloorInfo from "../components/FloorInfo";
import FloorPriceInfo from "../components/FloorPriceInfo";
import MappedModel from "../components/MappedModel";
import UnitFilter from "../components/UnitFilter";
import mappingsData from "../data/mappings.json";
import DataMapping from "../data/types/DataMapping";
import Mapping from "../graphics/mapping";
import Instructions from "../Instructions";
import RightPanel from "../RightPanel";


export default function Home(props: { dark: boolean }) {
    const [activeMapping, setActiveMapping] = useState<Mapping | null>();
    const [selected, setSelected] = useState<Mapping>();

    const navigate = useNavigate();

    function onHover(mapping: Mapping | null) {
        setActiveMapping(mapping);
    }

    function onClick(mapping: Mapping) {
        navigate(mapping.link);
    }

    function onFilterUnitHover(unit: { unit: string; floor_color: string }) {
        setSelected({
            color: parseInt(unit.floor_color, 16),
            id: unit.unit,
            name: unit.unit,
            link: "",
        });
    }

    function onLoaded() {}

    const showInstructions =
        localStorage.getItem("instructions_shown") === null;

    if (showInstructions) {
        localStorage.setItem("instructions_shown", "true");
    }

    const data = mappingsData["main"] as DataMapping;

    return (
        <>
            {activeMapping != null ? (
                <FloorInfo>
                    <FloorPriceInfo
                        floorName={activeMapping.name}
                        priceInfo={activeMapping.info}
                    />
                </FloorInfo>
            ) : null}

            <div className="flex h-full">
                <div className="grow h-full mr-[250px]">
                    
                    <MappedModel
                        config={{ cover: true, keepSelection: true }}
                        data={data}
                        highlight={selected}
                        onClick={onClick}
                        onHover={onHover}
                        onLoaded={onLoaded}
                    />
                </div>
                <div className="z-[1000]">
                    <RightPanel>
                        <UnitFilter onHover={onFilterUnitHover} />
                    </RightPanel>
                </div>

                {showInstructions ? <Instructions /> : null}
            </div>
        </>
    );
}
