import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import FloorPlan from './pages/FloorPlan';
import Home from './pages/Home';
import Unit from './pages/Unit';
import { isMobile } from 'react-device-detect'


function App() {
  const [dark, setDark] = useState(false);

  return (
    <div className={'flex flex-col h-screen overflow-hidden ' + (dark ? 'dark' : '')}>
      {isMobile ? <div className='m-auto text-center'>
        <div className="font-bold mb-2">Coming soon</div>
        <div>Sorry, deze interactieve 3D view is nog niet beschikbaar op mobiele apparaten. Bekijk hem op jouw desktop.</div>
      </div> : <>
        {/* <Header /> */}

        <Routes>
          <Route path='/' element={<Home dark={dark}/>} />
          <Route path='/floor/:id' element={<FloorPlan />} />
          <Route path='/unit/:id' element={<Unit />} />
        </Routes>
      </>}
    </div>
  );
}

export default App;
