import { useLayoutEffect, useRef, useState } from "react";
import DataMapping from "../data/types/DataMapping";
import Mapping from "../graphics/mapping";
import RotatingView, { RotatingViewConfig } from "../graphics/rotatingView";
import Loading from "../components/Loading";

export default function MappedModel(props: {
    onHover: (mapping: Mapping | null) => void;
    onClick: (mapping: Mapping) => void;
    onLoaded?: () => void;
    data: DataMapping;
    config: RotatingViewConfig;
    highlight?: Mapping;
}) {
    const [loading, setLoading] = useState(true);
    const [loadProgress, setLoadProgress] = useState(0);
    const [view, setView] = useState<RotatingView>();
    const [showInstructions, setShowInstructions] = useState(true);
    const [hideInstructions, setHideInstructions] = useState(false);

    if (props.highlight && view) {
        const highlight = props.highlight;
        view.highlight(highlight);
    }

    function lpad(str: string, n: number = 4): string {
        while (str.length < n) str = "0" + str;
        return str;
    }

    const canvasRef = useRef<HTMLCanvasElement>(null);

    useLayoutEffect(() => {
        const data = props.data;

        let mpngs: Mapping[] = [];

        if (data.links) {
            mpngs = data.links.map((l) => ({
                name: l.title,
                color: Number.parseInt(l.color, 16),
                id: l.title,
                link: l.link,
                info: {
                    minPrice: l.min_price || 0,
                    maxPrice: l.max_price || 0,
                },
                disabled: !!l?.disabled
            }));
        }

        function loaded() {
            setLoading(false);
            setShowInstructions(true);
            setTimeout(() => {
                setShowInstructions(false);
                setHideInstructions(true);
            }, 0);
            if (props.onLoaded) props.onLoaded();
        }

        const rotatingView = new RotatingView(
            canvasRef.current!,
            data.renders,
            data.mappings,
            mpngs,
            props.config,
            loaded,
            (progress) => {
                setLoadProgress(progress);
            }
        );

        rotatingView.onHover = (mapping: Mapping | null) => {
            props.onHover(mapping);
        };

        rotatingView.onClick = (mapping: Mapping) => {
            props.onClick(mapping);
        };

        setView(rotatingView);
    }, []);

    return (
        <div className="w-full h-full relative" style={{ zIndex: 0 }}>
            <canvas className="w-full h-full" ref={canvasRef}></canvas>
            {loading ? (
                <>
                    {props.data.loading ? (
                        <div
                            className={
                                "absolute top-0 left-0 w-full h-full flex bg-center bg-no-repeat " +
                                (props.config.cover ? "bg-cover" : "bg-contain")
                            }
                            style={{
                                backgroundImage:
                                    "url(" + props.data.loading + ")",
                            }}
                        ></div>
                    ) : null}
                    <div className="absolute top-0 left-0 w-full h-full flex">
                        <Loading progress={loadProgress} />
                    </div>
                </>
            ) : null}

            {showInstructions ? (
                <div className="fixed w-full h-full top-0 left-0 flex text-sm z-[1000] text-center"></div>
            ) : null}
        </div>
    );
}
