import { BLEND_MODES } from "@pixi/constants";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { MultiColorReplaceFilter } from "@pixi/filter-multi-color-replace";
import { InteractionEvent } from "@pixi/interaction";
import { Loader } from "@pixi/loaders";
import { Sprite } from "@pixi/sprite";
import { AnimatedSprite } from "@pixi/sprite-animated";
import config from "../config";
import Mapping from "./mapping";
import { RotatingViewConfig } from "./rotatingView";

function wrap(x: number, w: number): number {
  if (x < 0) x = w + (x % w);
  if (x >= w) x = x % w;
  return x;
}

export class Model extends Container {
  anim?: AnimatedSprite;
  mappingAnim?: AnimatedSprite;
  initX?: number;
  initFrame?: number;
  loader?: Loader;
  highlight?: Container;
  masks: Texture[] = [];
  highlightMask?: Sprite;
  mappingDatas?: ImageData[];
  mappings: Mapping[];
  lastHoveredMapping: Mapping | null = null;
  rotated = false;
  config: RotatingViewConfig;

  private _allowRotation = false;
  private _currentIndex = 0;
  private _selectedColor = 0;

  set selectColor(color: number) {
    if (color !== this._selectedColor) {
      if (this.highlightMask && this.highlightMask.filters) {
        this.setImage(this._currentIndex);

        const filter: MultiColorReplaceFilter = this.highlightMask
          .filters[0] as MultiColorReplaceFilter;

        // mapping.color is the color for the overlay of the mapping to show the floors. Change the first one after the question mark. Stadshave color: "0xaa975c"
        filter.replacements = this.mappings.map((mapping) => [
          mapping.color,
          mapping.color === color ? 0x99ccff : 0xffffff,
        ]);
      }
    }
    this._selectedColor = color;
  }

  get selectColor() {
    return this._selectedColor;
  }

  async getImageData(url: string, cb?: () => void) {
    return new Promise<ImageData>((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas") as HTMLCanvasElement;
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d", {
          alpha: false,
          willReadFrequently: true,
        });

        if (ctx) {
          ctx.drawImage(img, 0, 0);
          resolve(ctx.getImageData(0, 0, img.width, img.height));
          if (cb) cb();
        }
      };
      img.src = url;
    });
  }

  setImage(index: number) {
    this._currentIndex = index;
    if (this.anim) {
      this.anim.gotoAndStop(index);
      if (this.highlightMask) this.highlightMask.texture = this.masks[index];
      if (this.mappingAnim) this.mappingAnim.gotoAndStop(index);
    }
  }

  pointerAction(event: InteractionEvent) {
    if (event.target != null && this.anim) {
      if (
        event.data.pressure > 0 &&
        this.initX !== undefined &&
        this.initFrame !== undefined
      ) {
        if (this._allowRotation) {
          const dx = event.data.global.x - this.initX;

          const frame = wrap(
            Math.floor(this.initFrame - (dx / config.rotationStep) * -1),
            this.anim.totalFrames
          );

          if (frame !== this._currentIndex) this.rotated = true;

          this.setImage(frame);
        }
      }

      if (this.anim && this.mappings && event.target != null) {
        const pos = event.data.getLocalPosition(this.anim);

        const x = Math.floor(pos.x + 1920 / 2);
        const y = Math.floor(pos.y + 1080 / 2);

        const dataPos = (y * 1920 + x) * 4;
        const cc = this.mappingDatas![this.anim.currentFrame].data.slice(
          dataPos,
          dataPos + 3
        );

        const color: number =
          ((cc[0] & 0xff) << 16) | ((cc[1] & 0xff) << 8) | (cc[2] & 0xff);

        if (color === 0) {
          if (this.config.keepSelection) return;
          this.lastHoveredMapping = null;
        }

        this.updateColor(color);
      }
    } else {
      this._allowRotation = false;
    }
  }

  updateColor(color: number) {
    const c = this.selectColor;

    if (c !== color) {
      let hasHover = false;
      for (const mapping of this.mappings)
        if (mapping.color === color && !mapping.disabled) {
          this.selectColor = color;
          this.lastHoveredMapping = mapping;
          this.emit("hover", mapping);
          hasHover = true;
          break;
        }

      if (!hasHover) {
        this.emit("clear");
      }
    }
  }

  constructor(
    imgUrls: string[],
    mappingUrls: string[],
    mappings: Mapping[],
    config: RotatingViewConfig,
    cb?: () => void,
    progressCB?: (progress: number) => void
  ) {
    super();

    this.mappings = mappings;
    this.config = config;

    let loaded = 0;
    let imgProgress = 0;

    function updateProgress() {
      if (progressCB) {
        progressCB(
          (loaded / mappingUrls.length) * 0.5 + (imgProgress / 100) * 0.5
        );
      }
    }

    Promise.all<ImageData>(
      mappingUrls.map((url) =>
        this.getImageData(url, () => {
          loaded++;
          updateProgress();
        })
      )
    )
      .then((result) => {
        this.mappingDatas = result;

        const loader = new Loader();

        loader.add(imgUrls);

        if (mappingUrls && mappingUrls.length === imgUrls.length) {
          loader.add(mappingUrls);
        } else {
          if (mappingUrls)
            throw new Error(
              "There should be as many mappings as there are images (given " +
                imgUrls.length +
                " images and " +
                mappingUrls.length +
                " mappings)"
            );
        }

        loader.onProgress.add(() => {
          imgProgress = loader.progress;
          updateProgress();
        });

        loader.load((resources) => {
          loaded++;
          const imageTextures: Texture[] = [];
          for (const img of imgUrls) {
            imageTextures.push(resources.resources[img].texture as Texture);
          }

          this.anim = new AnimatedSprite(imageTextures);
          this.anim.anchor.set(0.5);
          this.anim.interactive = true;
          this.addChild(this.anim);

          this.highlightMask = new Sprite();
          this.highlightMask.anchor.set(0.5);
          this.addChild(this.highlightMask);

          this.highlightMask.filters = [
            new MultiColorReplaceFilter(
              this.mappings.map((floor) => [floor.color, 0xffffff]),
              0.05
            ),
          ];

          this.highlightMask.filters[0].blendMode = BLEND_MODES.MULTIPLY;

          this.anim.on("pointerdown", (event: InteractionEvent) => {
            this.initX = event.data.global.x;
            this.initFrame = this.anim?.currentFrame;
            this.pointerAction(event);
            this._allowRotation = true;
          });
          this.anim.on("pointerup", (event: InteractionEvent) => {
            if (this.lastHoveredMapping && !this.rotated) {
              this.emit("click", this.lastHoveredMapping);
            }
            this.rotated = false;
            this._allowRotation = false;
          });
          this.anim.on("pointermove", this.pointerAction.bind(this));

          if (mappingUrls) {
            this.highlightMask.texture = resources.resources[mappingUrls[0]]
              .texture as Texture;
            for (const mapping of mappingUrls) {
              this.masks.push(resources.resources[mapping].texture as Texture);
            }
          }

          if (cb) cb();
        });

        this.loader = loader;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
