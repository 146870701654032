import { PointerEvent as ReactPointerEvent, useState } from "react";

interface SliderProps {
    min: number,
    max: number,
    value: number,
    format?:(val:number)=>string,
    onChange: (val: number) => void
}

export default function Slider(props: SliderProps) {
    const [isDrag, setDrag] = useState(false);
    const [value, setValue] = useState(props.value);

    const [measured, setMeasured] = useState(false);
    const [element, setElement] = useState<HTMLDivElement>();

    let fromPageX: number;

    function pointerMove(event: React.PointerEvent<HTMLDivElement>) {
        if (isDrag) {
        }
    }

    function globalMove(event: PointerEvent) {
        if (element != null) {
            const dx = event.pageX - fromPageX;
            const chval = dx * ((props.max - props.min) / element.clientWidth);
            const newVal = Math.max(props.min, Math.min(props.max, value + chval));

            setValue(newVal);
            props.onChange(newVal);
        }
    }

    function pointerDown(event: ReactPointerEvent) {
        event.nativeEvent.preventDefault();
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.stopPropagation();

        fromPageX = event.pageX;

        setDrag(true);

        window.addEventListener('pointermove', globalMove);
        window.addEventListener('pointerup', () => {
            pointerUp();
        });
    }

    function pointerUp() {
        setDrag(false);
        window.removeEventListener('pointermove', globalMove);
    }

    function ref(el: HTMLDivElement) {
        if (!measured) {
            setMeasured(true);

            if (el) {
                setElement(el);
            }
        }
    }

    function formatted(v:number) {
        return props.format ? props.format(v) : v;
    }

    const knobPxPos = element != null ? Math.floor((value - props.min) / (props.max - props.min) * element.clientWidth) : 0;

    return <div className='h-[30px]' onPointerMove={pointerMove}>
        <div className='flex h-full relative'>
            <div className='grow  border-black h-1 m-auto relative' ref={ref}>
                <div className="h-[1px] bg-black "></div>
                <div className={'m-1 absolute rounded-full pl-3 pr-3 bg-black text-white h-[30px] text-sm flex top-[-15px] cursor-pointer select-none transition-transform' + (isDrag ? ' scale-125' : '')} style={{ left: `${knobPxPos}px` }} onPointerDown={pointerDown} onPointerUp={pointerUp}>
                    <span className='m-auto'>{formatted(value)}</span>
                </div>
            </div>
            <div className='text-sm m-auto pl-2'>{formatted(props.max)}</div>
        </div>

    </div>
}
