import { price } from "../util/format";


// Change color in between brackets to change the small round icon next to the min/max floor indicator
export default function FloorPriceInfo(props: { floorName: string, priceInfo?: { minPrice: number, maxPrice: number } | null }) {
    return <div className='flex font-bold w-full justify-between text-sm'>
        <div className='flex text-[#aa975c]'>
            <div className='mr-3 opacity-60 m-auto '>
                <div className="w-4 h-4 bg-[#aa975c] rounded-full"></div>
            </div>
            <div>{props.floorName}</div>
        </div>
        {props.priceInfo ?
            <div className="flex text-[#988651]">
                {price(props.priceInfo.minPrice)} - {price(props.priceInfo.maxPrice)}
            </div> : null}
    </div>
}
