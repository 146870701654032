import { useState } from "react";
import { useNavigate } from "react-router-dom";
import data from "../data/units.json";
import { price as priceFormat } from "../util/format";
import DataTable, { DataHeader } from "./DataTable";
import Favorites from "./Favorites";
import Fold from "./Fold";
import NumberInput from "./NumberInput";
import Slider from "./Slider";
import TextField from "./TextField";
import "animate.css";


export default function UnitFilter(props: {
    onHover?: (unit: any) => void;
}) {
    const minSurface = data.reduce((prev, current) => current.surface < prev ? current.surface : prev, 999999);
    const maxSurface = data.reduce((prev, current) => current.surface > prev ? current.surface : prev, 0);

    const minPrice = data.reduce((prev, current) => current.price < prev ? current.price : prev, 999999);
    const maxPrice = data.reduce((prev, current) => current.price > prev ? current.price : prev, 0);

    const [bedrooms, setBedrooms] = useState(1);
    const [floor, setFloor] = useState(0);

    const [surface, setSurface] = useState(minSurface);
    const [price, setPrice] = useState(minPrice);

    const headers: DataHeader[] = [
        { title: 'Beschikb.', field: 'available', sort: true },
        { title: 'Unit', field: 'unit', sort: true },
        { title: 'Opp. (in m²)', field: 'surface', sort: true },
        { title: 'Prijs VON', field: 'price', sort: true, format: (v) => priceFormat(parseFloat(v))  },
        // { title: 'Etage', field: 'floor', sort: true},
        // { title: 'Slaapkamers', field: 'bedrooms', sort: true },
    ];

    const filteredData = data.filter((item) => item.surface >= surface && item.price >= price && item.floor >= floor && item.bedrooms >= bedrooms);

    const navigate = useNavigate();

    function selectUnit(unit: any) {
        navigate(`/unit/${unit.unit}`);
    }

    function hoverUnit(unit: any) {
        if (props.onHover)
            props.onHover(unit);
    }

    const foldTitle = `Gefilterde units (${filteredData.length}/${data.length})`;

    return <>
        <div className='pt-[30px] pl-[15px] pr-[15px] pb-[30px] animate__animated animate__fadeIn animate'>
            <div className='font-bold text-lg mb-5'>Filters</div>
            <div className='font-bold text-sm'>Minimale oppervlakte in m²</div>
            <Slider min={minSurface} max={maxSurface} format={(v) => `${Math.floor(v)}`} value={surface} onChange={(val) => setSurface(val)} />
            
            <div className='font-bold text-sm mt-5'>Minimale prijs</div>
            <Slider min={minPrice} max={maxPrice} format={(v) => `${priceFormat(v)}`} value={price} onChange={(val) => setPrice(val)} />

            {/* Slaapkamers */}
            <div className='flex mt-5'>
                <div className="flex flex-col mr-6">
                    <div className="text-sm">Slaapk. (min.)</div>
                    <NumberInput min={1} value={bedrooms} onChange={(val) => { setBedrooms(val) }} />
                </div>
                <div className="flex flex-col mr-6">
                    <div className="text-sm">Etage (min.)</div>
                    <NumberInput min={1} value={bedrooms} onChange={(val) => { setBedrooms(val) }} />
                </div>
            </div>
        </div>

        <div className='pt-[30px] h-full overflow-auto'>
            {/* <Fold title="Favorieten" open={false}>
                <Favorites />
            </Fold> */}

            <div className="mb-2 mt-2" />

            <Fold title={foldTitle} open={true}> 
                <div className="ml-[15px] mr-[15px] animate__animated animate__fadeIn animate__slow">
                    <DataTable
                        data={filteredData}
                        headers={headers}
                        onClick={(item) => selectUnit(item)}
                        onHover={(item) => hoverUnit(item)}
                    />
                </div>
            </Fold>
        </div>
    </>
}
