import { ArrowRightIcon } from '@heroicons/react/solid';
import { useState } from "react";
import 'animate.css';

export default function RightPanel(props: { children: Element | JSX.Element }) {
    const [maximized, setMaximized] = useState(true);

    return <div className={(maximized ? 'w-[347px]' : 'w-[142px]') + ' h-full duration-750 transition-all fixed right-0 bg-[#fdfdfd] border border-slate-100 dark:bg-black z-[100]'}>
        <div className={"absolute top-[27px] transition-all duration-750 " + (maximized ? 'left-[-16px]' : 'left-[55px] ')}>
            {/* <button className={"w-[32px] h-[32px] bg-[#F2F2F2] rounded-full flex shadow duration-500 transition-all z-[1000]" + (maximized ? '' : 'rotate-180')} onClick={(e) => { e.preventDefault(); setMaximized(!maximized); }}>
                <ArrowRightIcon className="w-5 h-5 m-auto" />
            </button> */}
        </div>
        {maximized ?
            (<div className="flex flex-col divide-y-[1px] divide-black h-full">
                <div className='p-[30px]'>
                    <div className='text-2xl font-thin font-serif animate__animated animate__fadeIn animate'>
                        <a href="https://stadshave.presendoo.com/#/">Stadshave</a>
                    </div>
                    <div className='font-serif text-sm animate__animated animate__fadeIn animate__slower'>
                        Hulst, Nederland
                    </div>
                </div>

                {props.children}

            </div>) : <div className="flex flex-col mt-[70px] text-center">
                <div className="font-bold text-sm">Toon filters</div>
            </div>}

    </div>
}
