import { ChevronDownIcon } from "@heroicons/react/solid";
import { useState } from "react";
import 'animate.css';

export default function Fold(props: { children: Element | JSX.Element, title: string, open: boolean }) {
    const [open, setOpen] = useState(props.open);

    return <div>
        <button className="flex pl-[15px] pr-[15px] font-bold" onClick={(e) => { e.preventDefault(); setOpen(!open); }}>
            <span>{props.title}</span> <ChevronDownIcon className={"w-5 h-5 transition-transform " + (open ? 'rotate-180' : 'rotate-0')} />
        </button>
        <div className={(open ? '' : 'hidden') + ' transition-all'}>
            {props.children}
        </div>
    </div>
}
