import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { Tooltip } from "flowbite-react";
import { useState } from "react";


export interface DataHeader {
    title: string | JSX.Element | Element;
    field: string;
    sort?: boolean;
    format?: (val: string) => string;
}

export default function DataTable(props: {
    headers: DataHeader[];
    data: { [key: string]: any }[];
    onClick?: (item: any) => void;
    onHover?: (item: any) => void;
}) {
    const [sortBy, setSortBy] = useState<string>('');
    const [sortDir, setSortDir] = useState('asc');

    function sort(by: string) {
        if (by === sortBy) {
            setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(by);
            setSortDir('asc');
        }
    }

   

    function click(item: any) {
        if (props.onClick)
            props.onClick(item);
    }

    function pointerEnter(item: any) {
        if (props.onHover)
            props.onHover(item);
    }

    function convertAvailableToIcon(input: String) {
      if (input === 'TRUE') {
        return (
          <Tooltip content={'Beschikbaar'}>
            <div className={'text-[#3BBE60]'}>◉</div>
          </Tooltip>
        );
      }
      if (input === 'OPTION') {
        return (
          <Tooltip content={'In optie'}>
            <div className={'text-[#FFA500]'}>◉</div>
          </Tooltip>
        );
      }
      if (input === 'FALSE') {
        return (
          <Tooltip content={'Verkocht'}>
            <div className={'text-[#FF0000]'}>◉</div>
          </Tooltip>
        );
      } else {
        return input;
      }
    }

    const sortedData = props.data.sort((a, b) => sortBy !== '' ? (a[sortBy] > b[sortBy] ? 1 : -1) * (sortDir === 'asc' ? 1 : -1) : 0);

    return <table className="table-auto w-full divide-y divide-black mt-3">
        <thead>
            <tr className="text-left">
                {props.headers.map(function (header) {
                    return <th key={header.field} className="p-1 ">
                        <button className={'flex text-sm text-bold' + (header.sort ? "hover:underline" : "pointer-events-none")} onClick={(e) => { e.preventDefault(); sort(header.field); }}>{header.title} {sortBy === header.field ?
                            sortDir === 'asc' ? <ChevronUpIcon className="w-3 h-3 m-auto" /> : <ChevronDownIcon className="w-3 h-3 m-auto" />
                            : null}
                        </button>
                    </th>
                })}
            </tr>
        </thead>
        <tbody className="divide-y">
            {sortedData.map(function (item, index) {
                return <tr key={'data_item_' + index} className="hover:bg-slate-100 cursor-pointer" onClick={() => click(item)} onPointerEnter={() => pointerEnter(item)}>
                    {props.headers.map(function (header) {
                        return <td key={header.field + '_' + index} className="p-1 text-center">
                            {header.format ? convertAvailableToIcon(header.format(item[header.field])) : convertAvailableToIcon(item[header.field])}
                        </td>
                    })}
                </tr>
            })}
        </tbody>
    </table>
}