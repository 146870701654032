import { ArrowRightIcon } from "@heroicons/react/outline";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useState } from "react";

function ArrowIcon(props: { icon: JSX.Element }) {
    return <div className="rounded-full m-auto bg-white/50 w-[60px] h-[60px] flex">
        <div className="m-auto">
            {props.icon}
        </div>
    </div>
}

export default function Gallery(props: { imgs: string[] }) {
    const [imgIndex, setImgIndex] = useState(0);

    function left() {
        if (imgIndex - 1 < 0)
            setImgIndex(props.imgs.length - 1);
        else
            setImgIndex(imgIndex - 1);
    }

    function right() {
        if (imgIndex + 1 === props.imgs.length)
            setImgIndex(0);
        else
            setImgIndex(imgIndex + 1);
    }

    return <div className="w-full h-full relative">
        {props.imgs.map(function (img, index) {
            return <div key={`photo-${index}`} className={"absolute w-full h-full bg-center bg-contain transition-opacity bg-no-repeat " + (index === imgIndex ? 'opacity-100' : 'opacity-0')} style={{ backgroundImage: `url(${img})` }}></div>
        })}
        {props.imgs.length > 1 ?
            <div className="absolute w-full h-full flex ">
                <div className="flex cursor-pointer p-6 hover:bg-white/10 transition-colors" onClick={() => left()}>
                    <ArrowIcon icon={<ArrowLeftIcon className="w-10 h-10" />} />
                </div>
                <div className="grow"></div>
                <div className="flex cursor-pointer p-6 hover:bg-white/10 transition-colors" onClick={() => right()}>
                    <ArrowIcon icon={<ArrowRightIcon className="w-10 h-10" />} />
                </div>
            </div> : null}
    </div>
}
