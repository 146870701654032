import data from "../data/units.json";
import BathIcon from "../icons/bath.png";
import BedIcon from "../icons/bed.png";
import RullerIcon from "../icons/ruller.png";
import TerraceIcon from "../icons/terrace.png";
import { price } from "../util/format";
import FavButton from "./FavButton";

interface UnitDescriptionProps {
    name: string;
    unitid: string;
}

export default function UnitDescription(props: UnitDescriptionProps) {

  const info = data.filter(unit => unit.unit == props.unitid)[0];

    return (
        <>
            <div className="flex justify-between">
                <div className="flex">
                    <div className="text-lg font-bold">{props.name}</div>
                    {/* <div className="w-5 h-5 m-auto ml-2">
                        <FavButton unitid={props.unitid} />
                    </div> */}
                </div>
                <div className="text-lg">{price(info.price)}</div>
            </div>

            <div className="text-sm w-full">
                <table className="table-fixed w-full mt-3 divide-y divide-gray">
                    <tbody>
                        <tr className="border-b-2 border-gray">
                            <td className="w-5 py-3 px-1">
                                <img src={RullerIcon} alt="Wonen in m2"></img>
                            </td>
                            <td className="">Wonen in m2</td>
                            <td className="text-right">{info.surface}m2</td>
                        </tr>
                        <tr className="border-b-2 border-gray">
                            <td className="w-5 py-3 px-1">
                                <img src={TerraceIcon} alt="Terras in m2"></img>
                            </td>
                            <td className="">Terras in m2</td>
                            <td className="text-right">{info.terras}</td>
                        </tr>
                        <tr className="border-b-2 border-gray">
                            <td className="w-5 py-3 px-1">
                                <img src={BedIcon} alt="Slaapkamers"></img>
                            </td>
                            <td className="">Slaapkamers</td>
                            <td className="text-right">{info.bedrooms}</td>
                        </tr>
                        <tr className="">
                            <td className="w-5 py-3 px-1">
                                <img src={BathIcon} alt="Badkamers"></img>
                            </td>
                            <td className="">Badkamers</td>
                            <td className="text-right">{info.bathrooms}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}
