
export default function CircleButton(props: { children: Element | JSX.Element, onClick?: () => void }) {
    function click(e: any) {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (props.onClick) props.onClick()
    }

    return <div className="w-[32px] h-[32px] rounded-full shadow-gray-700 flex bg-[#F2F2F2] hover:scale-125 transition-all cursor-pointer" onClick={click}>
        {props.children}
    </div>
}