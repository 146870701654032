import { useState } from 'react';
import { useParams } from 'react-router-dom';
import BackButton from '../components/BackButton';
import Gallery from '../components/Gallery';
import PButton from '../components/PButton';
import TextInput from '../components/TextInput';
import UnitDescription from '../components/UnitDescription';
import data from '../data/units.json';
import PlanIcon from '../icons/plan.png';
import RightPanel from '../RightPanel';
import { Tooltip } from 'flowbite-react';

function ContentButton(props: {
  children: Element | JSX.Element;
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
}) {
  return (
    <div className="w-[60px] h-[60px] flex" onClick={() => props.onClick()}>
      <div
        className={
          'w-[35px] h-[35px] m-auto hover:bg-gray-200 flex transition-all  p-1' +
          (props.active ? 'bg-gray-200 p-1' : '')
        }
      >
        <div
          className={'m-auto' + (props.disabled === true ? ' opacity-30' : '')}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}

function convertAvailableToText(input: String) {
  if (input === 'TRUE') {
    return (
      <Tooltip content={'Beschikbaar'}>
        <div className={'text-[#3BBE60] text-sm'}>Beschikbaar</div>
      </Tooltip>
    );
  }
  if (input === 'OPTION') {
    return (
      <Tooltip content={'In optie'}>
        <div className={'text-[#FFA500] text-sm'}>In optie</div>
      </Tooltip>
    );
  }
  if (input === 'FALSE') {
    return (
      <Tooltip content={'Verkocht'}>
        <div className={'text-[#FF0000] text-sm'}>Verkocht</div>
      </Tooltip>
    );
  } else {
    return input;
  }
}

function ContentSwitch(props: {
  content: string;
  onChange: (content: string) => void;
}) {
  return (
    <div className="fixed left-[24px] bottom-[24px] bg-white flex">
      {/* <ContentButton active={props.content === 'gallery'} onClick={() => props.onChange('gallery')}>
            <img src={PhotoIcon} alt="" />
        </ContentButton>

        <ContentButton active={props.content === '3d'} onClick={() => props.onChange('3d')}>
            <img src={ThreeDIcon} alt="" />
        </ContentButton>

        <ContentButton active={props.content === 'video'} onClick={() => props.onChange('video')}>
            <img src={VideoIcon} alt="" />
        </ContentButton> */}

      {false ? (
        <ContentButton onClick={() => null}>
          <img src={PlanIcon} alt="" />
        </ContentButton>
      ) : null}
    </div>
  );
}

export default function Unit() {
  const { id } = useParams();

  const info = data.filter((item) => item.unit == id)[0];

  const [content, setContent] = useState('gallery');
  const [email, setEmail] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailAcknowledgeMessage, setEmailAcknowledgeMessage] = useState(false);

  async function sendMail() {
    setLoading(true);

    try {
      const response = await fetch(
        'https://hooks.zapier.com/hooks/catch/12904687/bgbv09e/',
        {
          method: 'post',
          body: JSON.stringify({ email }),
        }
      ).then((r) => r.json());

      setEmail('');
      setEmailAcknowledgeMessage(true);

      setTimeout(() => {
        setEmailAcknowledgeMessage(false);
      }, 5000);
    } catch (error) {
      console.error(error);
    }

    window.location.href =
      'mailto:info@kindtenbiesbroeck.nl?subject=' +
      'Stadshave: interesse in unit ' +
      encodeURIComponent(info.unit) +
      '&from=' +
      encodeURIComponent(email);

    setLoading(false);
  }

  return (
    <>
      <BackButton />

      <div className="flex h-full mr[300px]">
        <div className="grow bg-black h-full p-24 mr-[300px]">
          {(function () {
            switch (content) {
              case 'gallery':
                return <Gallery imgs={info.images} />;
              case '3d':
                return (
                  <div className="relative w-full h-full">
                    <div className="absolute w-full h-full flex"></div>
                    <iframe
                      className="w-full h-full absolute"
                      title="3d view"
                      src=""
                    />
                  </div>
                );
              case 'video':
                return <video className="w-full h-full" src="" autoPlay />;
              default:
                return null;
            }
          })()}
        </div>
        <RightPanel>
          <>
            <div className="p-[20px]">
              <div>{convertAvailableToText(info.available)}</div>
              <div className="mb-3">
                <UnitDescription
                  name={`Appartement ` + info.unit}
                  unitid={id!}
                />
              </div>

              {/* <button className="bg-[#F2F2F2] hover:bg-[#b6b6b6] inline-block rounded-lg text-sm">
                            <div className="flex p-2 ml-2 mr-2">
                                <div className="m-auto mr-2">
                                    <DownloadIcon className="w-3 h-3" />
                                </div>
                                <div className="div">
                                    Download pdf
                                </div>
                            </div>
                        </button> */}

              <div className="mt-8 pt-2 border-t-2 border-slate-300">
                <div className="font-bold mb-3 mt-2">Neem contact op</div>
                <TextInput
                  name="email"
                  type="email"
                  value={email}
                  onChange={(val) => setEmail(val)}
                  disabled={loading}
                />
                <div className="form-check inline-block">
                  <label className="form-check-label inline-block text-gray-800 cursor-pointer">
                    <div className="text-sm mb-2 mt-2 text-gray-500">
                      {emailAcknowledgeMessage ? (
                        <div className="text-green-800 font-bold bg-green-200 p-2 mb-1 text-center transition-all duration-300">
                          Email ontvangen
                        </div>
                      ) : null}
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={(e) => setAgreed(e.target.checked)}
                      ></input>
                      Ik ga akkoord met de{' '}
                      <a
                        href="https://www.kindtenbiesbroeck.nl/contact/privacy-policy"
                        className="text-black underline"
                        target="_blank"
                      >
                        privacyverklaring
                      </a>{' '}
                      en erken dat mijn gegevens worden verwerkt volgens de{' '}
                      <a
                        href="https://www.kindtenbiesbroeck.nl/contact/privacy-policy"
                        className="text-black underline"
                        target="_blank"
                      >
                        privacyverklaring
                      </a>
                      .
                    </div>
                  </label>
                </div>
                <div className="pt-3">
                  <PButton
                    title="Ik ben geïnteresseerd"
                    onClick={sendMail}
                    disabled={!agreed || loading}
                  />
                </div>
              </div>
            </div>
            <div></div>
          </>
        </RightPanel>
      </div>

      <ContentSwitch
        content={content}
        onChange={(content) => setContent(content)}
      />
    </>
  );
}
