import { ArrowLeftIcon, MinusIcon, PlusIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../components/BackButton";
import CircleButton from "../components/CircleButton";
import FloorInfo from "../components/FloorInfo";
import MappedModel from "../components/MappedModel";
import PButton from "../components/PButton";
import UnitDescription from "../components/UnitDescription";
import UnitFilter from "../components/UnitFilter";
import Mapping from "../graphics/mapping";
import RightPanel from "../RightPanel";
import { Tooltip } from "flowbite-react";

import mappingData from '../data/mappings.json'
import DataMapping from "../data/types/DataMapping";

function ChangeFloorButton(props: { children: Element | React.ReactElement, to: string }) {
    const navigate = useNavigate();

    function click() {
        navigate(props.to);
        setTimeout(() => {
            window.location.reload();
        });
    }

    return <div onClick={click} className="w-[22px] h-[22px] rounded-full bg-[#F2F2F2] flex cursor-pointer">
        <div className="m-auto">
            {props.children}
        </div>
    </div>
}

function convertAvailableToIcon(input: String) {
    if (input === 'TRUE') {
      return (
        <Tooltip content={'Beschikbaar'}>
          <div className={'text-[#3BBE60] text-sm'}>Beschikbaar</div>
        </Tooltip>
      );
    }
    if (input === 'OPTION') {
      return (
        <Tooltip content={'In optie'}>
          <div className={'text-[#FFA500] text-sm'}>In optie</div>
        </Tooltip>
      );
    }
    if (input === 'FALSE') {
      return (
        <Tooltip content={'Verkocht'}>
          <div className={'text-[#FF0000] text-sm'}>Verkocht</div>
        </Tooltip>
      );
    } else {
      return input;
    }
  }

export default function FloorPlan() {
    const navigate = useNavigate();

    const [selected, setSelected] = useState<Mapping>();

    function onClick(mapping: Mapping) {
        navigate(`/${mapping.link}`);
    }

    const { id } = useParams();

    const data = (mappingData as any)[`${id}`] as DataMapping;

    const links = data['links']!;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [current, setCurrent] = useState(links[0]);

    function onLoaded() {
        if (links) {
            /*
            setSelected({
                color: 0xff00ff,
                id: links[5].colorKey,
                name: links[5].title,
            });
            */
        }
    }

    function updateIndex(idx: number) {
        setCurrentIndex(idx);
        setCurrent(links[idx]);
        setSelected({
            color: parseInt(links[idx].color, 16),
            id: links[idx].color,
            name: links[idx].title,
            link: links[idx].link
        });
    }

    function prevUnit() {
        const newIndex = currentIndex - 1 < 0 ? links.length - 1 : currentIndex - 1;
        updateIndex(newIndex);
    }

    function nextUnit() {
        const newIndex = currentIndex + 1 >= links.length ? 0 : currentIndex + 1;
        updateIndex(newIndex);
    }

    function seeMore() {
        navigate(`/${links[currentIndex].link}`);
    }

    return <>
        {/* <FloorInfo>
            <div className="flex text-sm justify-between">
                <div className="flex">
                    <ChangeFloorButton to="/floor/demo_floor_1">
                        <MinusIcon className="w-3 h-3" />
                    </ChangeFloorButton>
                    <div className="ml-2 mr-2 font-bold">{data.header}</div>
                    <ChangeFloorButton to="/floor/demo_floor_2">
                        <PlusIcon className="w-3 h-3" />
                    </ChangeFloorButton>
                </div>

                <div className="text-[#828282]">
                    &euro;275.000 - &euro;315.000
                </div>
            </div>
        </FloorInfo> */}

        <BackButton to="/" />


        <div className='flex h-full mr-20 '>
            <div className='grow bg-white h-full p-24 mr-24'>
                
                    <MappedModel
                        config={{ cover: false, keepSelection: true }}
                        data={data}
                        highlight={selected}
                        onClick={onClick}
                        onHover={() => null}
                        onLoaded={onLoaded}
                    />
            
                
            </div>
            <div className="z-[1000]">
                <RightPanel>
                    <UnitFilter />
                </RightPanel>
            </div>
        </div>

        <div className="fixed left-[22px] bottom-[18px] w-[390px] h-[290px] bg-[#fdfdfd] border border-slate-100">
            <div className="pl-[23px] pr-[23px] pb-[15px] pt-[16px] flex flex-col justify-between h-full w-full">
            
                <UnitDescription unitid={current.title} name={current.title} />

                <div className="w-[148px]">
                    <PButton title="See more" onClick={seeMore} />
                </div>

            </div>

            <div className="absolute top-[50%]">
                <div className="absolute top-[-16px] left-[-16px]">
                    <CircleButton onClick={prevUnit}>
                        <ArrowLeftIcon className="m-auto w-5 h-5" />
                    </CircleButton>
                </div>
                <div className="absolute top-[-16px] left-[374px]">
                    <CircleButton onClick={nextUnit}>
                        <ArrowLeftIcon className="m-auto w-5 h-5 rotate-180" />
                    </CircleButton>
                </div>
            </div>
        </div>
    </>
}